import {
  CustomRequestEvent,
  CustomRequestEventLogin,
  CustomRequestEventSignup,
  DataLayerService,
  OriginalLocationEvent,
  PageDetailsEvent,
} from '@/lib/data-layer/data-layer';
import { PageType } from '@/lib/data-layer/types';
import { DeviceContext } from '@/src/store/contexts/device-context';
import { OriginalLocationContext } from '@/src/store/contexts/original-location-context';
import { useCallback, useContext } from 'react';

export const useDataLayer = () => {
  const dataLayer = new DataLayerService();
  const { isBot } = useContext(DeviceContext);
  const { originalUrl } = useContext(OriginalLocationContext);

  const sendOriginalLocationEvent = useCallback(() => {
    const url = originalUrl || (typeof window !== 'undefined' ? window.location.href.toString() : '');
    dataLayer.sendEvent(new OriginalLocationEvent(url));
  }, [dataLayer, originalUrl]);

  const sendPageDetailsEvent = useCallback(
    (pageData: any, type: PageType) => {
      const clientData = {
        HK_botStatus: isBot,
      };
      dataLayer.sendEvent(new PageDetailsEvent({ pageData, clientData, type }));
    },
    [isBot]
  );

  const sendCustomRequestEvent = useCallback(
    (eventLabel: string, eventAction = 'Kobi', eventCategory: string, eventValue: string) => {
      dataLayer.sendEvent(new CustomRequestEvent(eventLabel, eventAction, eventCategory, eventValue));
    },
    []
  );

  const sendCustomRequestEventLogin = useCallback(
    (eventLabel: string, eventAction = 'Kobi', eventCategory: string, memberId: string) => {
      dataLayer.sendEvent(new CustomRequestEventLogin(eventLabel, eventAction, eventCategory, memberId));
    },
    []
  );

  const sendCustomRequestEventSignup = useCallback(
    (eventLabel: string, eventAction = 'Kobi', eventCategory: string, memberId: string) => {
      dataLayer.sendEvent(new CustomRequestEventSignup(eventLabel, eventAction, eventCategory, memberId));
    },
    []
  );

  return {
    dataLayer,
    sendOriginalLocationEvent,
    sendPageDetailsEvent,
    sendCustomRequestEvent,
    sendCustomRequestEventLogin,
    sendCustomRequestEventSignup,
  };
};
