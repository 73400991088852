import { ClipboardChecklist, DobCloseIcon, GoldMedal, HandphoneLock } from '@/src/features/ui/icons/icons';
import ImageWithFallback from '@/src/features/ui/image/image-with-fallback';
import { ResponseBankDigitalOnBoardingInfo } from '@/src/interfaces/data-contracts';
import classNames from 'classnames';
import React from 'react';

type Props = {
  product: ResponseBankDigitalOnBoardingInfo;
  isPopupOpen: boolean;
  closePopup: React.MouseEventHandler<HTMLElement>;
};
const QRComponent: React.FC<Props> = ({ isPopupOpen, closePopup, product }) => {
  return (
    <section className={classNames('qr-container flex justify-center', !isPopupOpen ? 'hidden' : '')}>
      <section className={'qr-popup fixed left-0 top-0 z-50 h-full w-full bg-black900/[0.59]'}>
        <section
          className={
            'qr-component absolute left-1/2 top-1/2 flex h-[613px] w-[546px] -translate-x-1/2 -translate-y-1/2 flex-row-reverse items-start justify-between gap-[30px] rounded-lg bg-white pl-[60px] pr-4 pt-4'
          }
        >
          <button className={'cursor-pointer'} onClick={closePopup}>
            <DobCloseIcon width={36} height={36} />
          </button>

          <section className={'flex flex-col gap-6 pt-6'}>
            <section className={'flex items-start justify-between'}>
              <div className={'flex items-center gap-5'}>
                <figure className="relative h-[52px] w-[104px]">
                  <ImageWithFallback src={product.bank?.logoPath} alt={product.name} title={product.name} fill />
                </figure>

                <div>
                  <h2 className={'text-base text-black1000'}>{product.name ?? ''}</h2>
                  <p className={'font-semibold text-black800'}>{product.qrPopUpTitle ?? ''}</p>
                </div>
              </div>
            </section>

            <section className={'flex w-[404px] items-center gap-1 rounded-lg border-2 border-gray400 py-2 pl-2 pr-4'}>
              <figure className="relative h-[141px] w-[143px]">
                <ImageWithFallback src={product.qrCoverImage} alt={product.name} title={product.name} fill />
              </figure>

              <div
                suppressHydrationWarning
                className={'w-[233px] text-base leading-[22px]'}
                dangerouslySetInnerHTML={{ __html: product.qrPopUpDescription ?? '' }}
              />
            </section>

            <section>
              <ul className={'flex flex-col gap-6'}>
                <li
                  className={
                    'relative ml-[21.5px] flex items-center gap-4 ' +
                    'before:-z-1 before:absolute before:bottom-0 before:left-[3.5px] before:top-[9px] before:h-[24px] before:border-l-2 before:content-[""]'
                  }
                >
                  <span className={'h-[9px] w-[9px] rounded-full bg-gray400'}></span>
                </li>

                <li className={'relative flex items-center gap-4'}>
                  <span
                    className={
                      'rounded-full border-2 border-gray400 p-2 ' +
                      'before:-z-1 before:absolute before:bottom-0 before:left-[25px] before:top-[52px] before:h-[24px] before:border-l-2 before:content-[""]'
                    }
                  >
                    <HandphoneLock width={32} height={32} />
                  </span>
                  <p className={'text-base font-semibold leading-8 text-black'}>
                    QR kodu okut, mobil uygulamayı indir.
                  </p>
                </li>
                <li className={'relative flex items-center gap-4'}>
                  <span
                    className={
                      'rounded-full border-2 border-gray400 p-2 ' +
                      'before:-z-1 before:absolute before:bottom-0 before:left-[25px] before:top-[52px] before:h-[24px] before:border-l-2 before:content-[""]'
                    }
                  >
                    <ClipboardChecklist width={32} height={32} />
                  </span>
                  <p className={'text-base font-semibold leading-8 text-black'}>Uzaktan banka müşterisi ol.</p>
                </li>
                <li className={'relative flex items-center gap-4'}>
                  <span
                    className={
                      'rounded-full border-2 border-gray400 p-2 ' +
                      'before:-z-1 before:absolute before:bottom-0 before:left-[25px] before:top-[52px] before:h-[24px] before:border-l-2 before:content-[""]'
                    }
                  >
                    <GoldMedal width={32} height={32} />
                  </span>
                  <p className={'text-base font-semibold leading-8 text-black'}>Bankaya özel avantajlardan faydalan!</p>
                </li>
                <ul>
                  <li
                    className={
                      'relative ml-[21.5px] flex items-center gap-4 ' +
                      'after:-z-1 after:absolute after:bottom-0 after:right-[3.5px] after:top-[9px] after:border-l-2 after:content-[""]'
                    }
                  >
                    <span className={'h-[9px] w-[9px] rounded-full bg-gray400'}></span>
                  </li>
                </ul>
              </ul>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};

export default QRComponent;
