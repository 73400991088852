'use client';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import Image from 'next/image';
import { useRouter, useSearchParams } from 'next/navigation';
import classNames from 'classnames';
import { ResponseNativeCard } from '@/src/interfaces/data-contracts';
import { DeviceContext } from '@/src/store/contexts/device-context';
import NativeCardModal from './native-card-modal';
import NativeCardTooltip from '../../ui/tooltip/native-card-tooltip';
import { RelLink } from '../../ui/rel-link';
import { BlogModulesActions } from './actions';
import Modal from '../../ui/modal/modal';

const getIntegrationTypeFromLink = (link: string | undefined): string | null => {
  if (!link) return null;

  if (link.startsWith('$dijikolay')) {
    return 'dijikolay';
  }
  return null;
};

type Props = {
  item: ResponseNativeCard;
};

export const NativeCard: React.FC<Props> = ({ item }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [integrationData, setIntegrationData] = useState<any>(null);
  const [isLoadingIntegrationData, setIsLoadingIntegrationData] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const router = useRouter();

  const { isMobile } = useContext(DeviceContext);
  const searchParams = useSearchParams();

  const rsParam = searchParams?.get('rs');

  const nativeCardPictures = item.nativeCardPictures;

  const defaultImageSrc = '';
  const imageSrc = isMobile
    ? nativeCardPictures?.mobileImagePath || nativeCardPictures?.tabletImagePath || defaultImageSrc
    : nativeCardPictures?.desktopImagePath || defaultImageSrc;

  const altTitle = nativeCardPictures?.altTitle || '';
  const imageAlt = `${altTitle} Kobi Kredisi`;

  const imageProps = {
    src: imageSrc,
    alt: imageAlt,
    width: 1120,
    height: 146,
    className: 'shadow-faq',
  };

  const integrationType = getIntegrationTypeFromLink(item.link ?? '');
  const hasIntegration = integrationType !== null;

  const hasLinkAndTarget = Boolean(item.link && item.target);
  const showTooltip = Boolean(item.isActiveInformationTooltip);

  const imageElement = (
    <Image
      {...imageProps}
      loading="eager"
      priority
      decoding="async"
      style={{ objectFit: 'contain' }}
      data-testid="nativeCardImage"
    />
  );

  const handleOpenPopup = useCallback(async () => {
    const dataLayer = (window && 'dataLayer' in window && (window.dataLayer as any[])) || null;
    dataLayer?.push({
      event: 'GAEvent',
      eventCategory: 'Ticari Krediler - View',
      eventAction: 'Pop Up',
      eventLabel: `${item.name}`,
    });

    setIsPopupOpen(true);

    if (hasIntegration && item.link) {
      const idMatch = item.link.match(/\d+/);
      const integrationId = idMatch ? Number(idMatch[0]) : null;

      if (integrationId !== null && integrationId >= 0) {
        setIsLoadingIntegrationData(true);
        setError(null);
        try {
          const data = await BlogModulesActions(integrationId);
          setIntegrationData(data);
        } catch (err) {
          console.error('Error fetching integration data:', err);
          setError('Failed to load integration data.');
        } finally {
          setIsLoadingIntegrationData(false);
        }
      } else {
        console.warn('Invalid integration ID');
        setError('Invalid integration ID.');
        setIsLoadingIntegrationData(false);
      }
    }
  }, [hasIntegration, item.link]);

  const handleClosePopup = useCallback(() => {
    setIsPopupOpen(false);
    setIntegrationData(null);
    setError(null);
  }, []);

  // Automatically open popup if rsParam is '1' and integrationType matches
  // this 'rs' param is for dijikolay only, this should be refactored to support
  // future integrations with other brands
  useEffect(() => {
    if (rsParam === '1' && hasIntegration) {
      setIsPopupOpen(true);
    }
  }, [rsParam, hasIntegration]);

  let content: React.JSX.Element;

  //@ts-ignore // Hotfix icin gerekli. API tarafina henuz eklenmedi.
  if (item.isConditionActive) {
    content = (
      <>
        <button
          onClick={handleOpenPopup}
          className={classNames('h-auto w-full shadow-faq')}
          data-testid="nativeCardButton"
        >
          {imageElement}
        </button>
        {isPopupOpen && (
          <Modal
            className="md:max-w-auto h-auto max-w-[343px] px-4 pb-6 pt-14 md:min-w-[800px] md:px-8 md:pb-8  md:pt-[60px]"
            show={isPopupOpen}
            onClose={handleClosePopup}
            data-testid="nativeCardModal"
          >
            <div
              className={`flex ${isMobile ? 'flex-col justify-between' : 'justify-center'} h-full w-full items-center`}
            >
              {item.link ? (
                <RelLink
                  href={item.link || ''}
                  target={item.target || '_blank'}
                  // className="h-auto w-full shadow-faq"
                  className={`flex ${isMobile ? 'flex-col justify-between' : 'justify-center'} h-full w-full items-center`}
                  data-testid="nativeCardLink"
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: item.conditions || '' }}
                    className="h-auto max-h-[80vh] w-full overflow-auto overscroll-contain md:max-h-[648px]"
                  ></div>
                </RelLink>
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: item.conditions || '' }}
                  className="h-auto max-h-[80vh] w-full overflow-auto overscroll-contain md:max-h-[648px]"
                ></div>
              )}
            </div>
          </Modal>
        )}
      </>
    );
  } else if (hasLinkAndTarget) {
    if (hasIntegration) {
      content = (
        <>
          <button
            onClick={handleOpenPopup}
            className={classNames('h-auto w-full shadow-faq')}
            data-testid="nativeCardButton"
          >
            {imageElement}
          </button>
          {isPopupOpen && (
            <NativeCardModal
              isPopupOpen={isPopupOpen}
              item={integrationData}
              isLoading={isLoadingIntegrationData}
              error={error}
              closePopup={handleClosePopup}
              integrationType={integrationType}
              rsParam={rsParam}
              data-testid="nativeCardModal"
            />
          )}
        </>
      );
    } else if (item.link && item.target) {
      content = (
        <RelLink
          href={item.link}
          target={item.target}
          className="h-auto w-full shadow-faq"
          data-testid="nativeCardLink"
        >
          {imageElement}
        </RelLink>
      );
    } else {
      // image only
      content = imageElement;
    }
  } else {
    content = imageElement;
  }

  return (
    <>
      {content}
      {showTooltip && (
        <NativeCardTooltip
          isActiveInfoTooltip={item.isActiveInformationTooltip ?? false}
          TooltipText={item.informationTooltipText ?? ''}
          isDesktop={!isMobile}
          tooltipTitle={item.informationTooltipText ?? ''}
          TooltipLeftToRight={item.informationTooltipLeftToRight ?? 0}
          TooltipTopToDown={item.informationTooltipTopToDown ?? 0}
        />
      )}
    </>
  );
};

export default NativeCard;
