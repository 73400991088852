'use client';
import classNames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CloseIcon } from '../../ui/icons/icons';
import ImageWithFallback from '../../ui/image/image-with-fallback';
import Heading from '../../ui/heading';
import { Button } from '../../ui/button/button';
import { DeviceContext } from '@/src/store/contexts/device-context';
import { dijikolayIntegration } from '@/src/integrations/dijikolayIntegration';
import { useDataLayer } from '@/src/hooks/use-datalayer';

type TNativeCardModal = {
  isPopupOpen: boolean;
  item: any;
  closePopup: () => void;
  integrationType?: string | null;
  rsParam?: string | null;
  isLoading?: boolean;
  error?: string | null;
};

const NativeCardModal: React.FC<TNativeCardModal> = ({
  isPopupOpen,
  item,
  closePopup,
  integrationType,
  rsParam,
  isLoading,
  error,
  ...rest
}) => {
  const { sendCustomRequestEvent } = useDataLayer();
  const { isMobile } = useContext(DeviceContext);
  //
  const [integrationStep, setIntegrationStep] = useState<'setIntegration' | 'afterIntegration'>('setIntegration');

  const integrationInitialized = useRef(false);
  useEffect(() => {
    if (isPopupOpen && integrationType && !integrationInitialized.current) {
      integrationInitialized.current = true;
      if (integrationType === 'dijikolay') {
        sendCustomRequestEvent('İş Bankası DijiKolay', 'Ticari Krediler', 'Native Kart Click', '');
        setIntegrationStep('afterIntegration');
        initializeIntegration();
      }
      // temporarily disabling the Dijikolay and DoB modal to allow users to access Dijikolay content immediately, until the DoB step is re-enabled.
      // if (rsParam === '1') {
      //   setIntegrationStep('afterIntegration');
      //   initializeIntegration();
      // } else {
      //   setIntegrationStep('setIntegration');
      // }
    }
  }, [isPopupOpen, integrationType, rsParam]);

  useEffect(() => {
    if (isPopupOpen) {
      document.documentElement.classList.add('modal-open');
    } else {
      document.documentElement.classList.remove('modal-open');
    }

    return () => {
      document.documentElement.classList.remove('modal-open');
    };
  }, [isPopupOpen]);

  const initializeIntegration = async () => {
    if (integrationType === 'dijikolay') {
      await dijikolayIntegration();
    }
  };

  const handleOnClick = () => {
    setIntegrationStep('afterIntegration');
    initializeIntegration();
  };

  const dijikolayContent = (
    <div id="plateau_ui_aof" className="overflow-auto">
      <div id="plateau_ui_placeholder"></div>
    </div>
  );

  const defaultModalContent = (
    <section className="flex flex-col gap-8">
      <section className="flex flex-col items-center gap-4">
        {item && item.name && item.imagePath && (
          <figure className="relative md:h-[72px] md:w-[150px]">
            <ImageWithFallback src={item.imagePath} alt={item.name} title={item.name} fill />
          </figure>
        )}
        {item && item.description && (
          <Heading tagType={'h3'} cls="md:text-xl text-base text-black900 text-center" bolded>
            {item.description}
          </Heading>
        )}
      </section>

      <section className="flex flex-col gap-8">
        {item && item.contents && item.contents.length > 0 && (
          <div className="border-bg200 flex flex-col gap-6 rounded-xl border p-5 md:flex-row md:items-center md:justify-between md:px-5 md:py-12">
            <div className="flex flex-col gap-3">
              <Heading tagType={'p'} cls="md:text-lg text-base text-gray1000 font-semibold">
                {item.contents[0].title}
              </Heading>
              <Heading tagType={'p'} cls="text-sm text-black700">
                {item.contents[0].shortDescription}
              </Heading>
            </div>

            <Button
              text={item.contents[0].buttonName}
              className="h-[48px] w-[161px] rounded-lg text-sm font-bold md:w-[177px] md:text-base"
              onClick={handleOnClick}
              hasArrow
            />
          </div>
        )}

        {item && item.contents && item.contents.length > 1 && (
          <div className="border-bg200 flex flex-col gap-6 rounded-xl border bg-gray100 p-5 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col gap-3">
              <Heading tagType={'p'} cls="md:text-lg text-base text-gray1000 font-semibold">
                {item.contents[1].title}
              </Heading>
              <Heading tagType={'p'} cls="text-sm text-black700">
                {item.contents[1].shortDescription}
              </Heading>
            </div>

            {!isMobile ? (
              <section className="bg-[url('/assets/sme/QR-Frame.png')] bg-cover bg-center bg-no-repeat bg-origin-border">
                <figure className="relative flex items-center justify-center md:h-[126px] md:w-[126px]">
                  <ImageWithFallback
                    src={item.contents[1].imagePath}
                    alt={item.contents[1].name}
                    title={item.contents[1].name}
                    width={110}
                    height={110}
                  />
                </figure>
              </section>
            ) : (
              <Button
                text={item.contents[1].buttonName}
                className="h-[48px] w-[161px] rounded-lg text-sm font-bold md:max-w-[177px] md:text-base"
                hasArrow
              />
            )}
          </div>
        )}
      </section>
    </section>
  );

  return (
    <>
      {isPopupOpen && (
        <section className="modal-body flex justify-center" data-testid="nativeCardModal">
          <section className="fixed left-0 top-0 z-50 flex h-full w-full flex-col bg-black900/[0.59] md:gap-8">
            <section
              className={classNames(
                'absolute left-1/2 top-1/2 w-11/12 -translate-x-1/2 -translate-y-1/2 overflow-auto p-6 px-4 pb-11',
                'rounded-xl bg-white',
                integrationStep === 'afterIntegration'
                  ? 'h-[99%] w-[98%] p-3'
                  : 'md:h-auto md:w-[600px] md:min-w-[600px] md:p-6'
              )}
            >
              <section
                className={classNames('flex flex-row-reverse', integrationStep === 'afterIntegration' && 'md:mb-2')}
              >
                <figure onClick={closePopup} className="inline-block cursor-pointer">
                  <CloseIcon width={24} height={24} />
                </figure>
              </section>
              {integrationStep === 'afterIntegration' ? dijikolayContent : defaultModalContent}
            </section>
          </section>
        </section>
      )}
    </>
  );
};

export default NativeCardModal;
