import { Bottom } from './bottom/bottom';
import { Kvkk } from './kvkk/kvkk';
import { Top } from './top/top';
import React from 'react';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';

type Props = {
  footerItems: ResponseMenuItem[];
  hideTop: boolean;
};
export const Footer: React.FC<Props> = ({ footerItems, hideTop = false }) => {
  return (
    <footer className={`bg-black150 ${!hideTop ? 'py-6 md:py-[60px]' : 'pb-8'}`} data-testid="footer">
      {!hideTop && <Top footerItems={footerItems} />}
      <Kvkk className={`pt-8 ${hideTop ? 'border-t-transparent pb-6' : 'py-6 md:py-8'}`} />
      <Bottom />
    </footer>
  );
};
