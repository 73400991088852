import { ResponseProductSearch, ResponseStoreFrontCreditCardDetail } from '@/src/interfaces/data-contracts';
import LinkLikeButton from '../link-like-button';
import ImageWithFallback from '../../ui/image/image-with-fallback';

type Props<T extends ResponseProductSearch | ResponseStoreFrontCreditCardDetail> = {
  item: T;
};

// list product
const hasRecourseUrl = (item: any): item is ResponseProductSearch => 'recourseUrl' in item;
// detail card
const hasRecourseLink = (item: any): item is ResponseStoreFrontCreditCardDetail => 'recourseLink' in item;

export const CustomerStatusModalContentCreditCard = <
  T extends ResponseProductSearch | ResponseStoreFrontCreditCardDetail,
>({
  item,
}: Props<T>) => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col items-center gap-4">
        <figure className="relative h-[72px] w-[150px] rounded-lg border border-background200">
          <ImageWithFallback className="px-3" src={item.bank?.logoPath} alt={item.name} title={item.name} fill />
        </figure>
        <p className="text-lg font-medium text-black900" dangerouslySetInnerHTML={{ __html: item.popUpText ?? '' }} />
      </div>
      <div className="flex w-full flex-col justify-center gap-3 md:flex-row">
        {hasRecourseUrl(item) && item.recourseUrl && (
          <LinkLikeButton
            external
            className="w-full text-base"
            text={item.bank?.name + ' Müşterisiyim'}
            href={item.recourseUrl}
          ></LinkLikeButton>
        )}
        {hasRecourseLink(item) && item.recourseLink && (
          <LinkLikeButton
            external
            className="w-full text-base"
            text={item.bank?.name + ' Müşterisiyim'}
            href={item.recourseLink}
          ></LinkLikeButton>
        )}
        {item.onBoardingUrl && (
          <LinkLikeButton
            external
            className="w-full px-4 py-3 text-base"
            text="Henüz Müşteri Değilim"
            href={item.onBoardingUrl}
          ></LinkLikeButton>
        )}
      </div>
    </div>
  );
};
