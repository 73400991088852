'use client';

import { ResponseWidget } from '@/src/interfaces/data-contracts';
import classNames from 'classnames';
import { useState } from 'react';
import FAQSchema from '../../schema/FAQSchema';
import AccordionTab from '../../ui/accordion-tab';
import { InnerContainer } from '../../ui/common/inner-container';
import Heading from '../../ui/heading';
import ShowMoreBtn from '../../ui/show-more-btn/show-more-btn';

const accordionTabItemClasses = 'rounded-lg mb-3 border border-black200 shadow-faq'; //ul > li ye class verir - her bir item
const accordionTitleClasses = 'font-bold text-base p-5'; //ul > li > a ya class verir - her bir baslik
const accordionTextClasses = 'flex text-black700 text-sm p-5 pt-0'; //ul > li > span a class verir - her bir icerik
const accordionVisibleTab = 0; //hangi tabin acik olacagini index e gore gosterir - 0: ilk tab / null: hepsi kapali
const accordionTabIcon = true; //basliklarin sag tarafindaki iconu gizler & gosterir
const accordionContentBtnVisible = false; //icerik alanlarinda buton çikmali mi?
const accordionContentBtnText = 'Sanal POS Ürünleri'; //icerik alaninda buton cikacak ise ne yazmali?
const accordionContentBtnClasses =
  'flex items-center justify-center rounded-md bg-purple500 text-white text-sm font-bold h-14 w-full md:w-[230px]'; //icerik alanda buton cikacaksa tasarimi nasil olmali?
let accordionHideSomeContent = true; //belli bir kisim gizlenmeli mi?
const accordionVisibleContentNumber = 4; //gizlenecekse kac tanesi gizlenmemeli?

type Props = {
  faqData: ResponseWidget;
  headingTextAlign?: 'left' | 'center' | 'right' | 'justify';
};
export const FaqSection: React.FC<Props> = ({ faqData, headingTextAlign }) => {
  const [contentShowStatus, setContentShowStatus] = useState(false);
  const onShowStateHandler = (incomingStatus: boolean | ((prevState: boolean) => boolean)) => {
    setContentShowStatus((incomingStatus) => !incomingStatus);
    accordionHideSomeContent = contentShowStatus;
  };

  if (faqData.details) {
    return (
      <>
        <FAQSchema faqData={faqData} />
        <InnerContainer className="flex flex-col gap-4 md:gap-8" data-testid="faqSection">
          <Heading
            tagType="h3"
            cls={`text-${headingTextAlign ?? 'center'} text-black1000 md:text-2xl text-lg`}
            bolded
            data-testid="title"
          >{`Sık Sorulan Sorular`}</Heading>
          <AccordionTab
            dataToAccordion={faqData.details}
            accordionTabItemClasses={classNames('', {}, accordionTabItemClasses)}
            accordionTitleClasses={accordionTitleClasses}
            accordionTextClasses={accordionTextClasses}
            accordionVisibleTab={accordionVisibleTab}
            accordionTabIcon={accordionTabIcon}
            accordionContentBtnText={accordionContentBtnText}
            accordionContentBtnClasses={accordionContentBtnClasses}
            accordionContentBtnVisible={accordionContentBtnVisible}
            accordionHideSomeContent={accordionHideSomeContent}
            accordionVisibleContentNumber={accordionVisibleContentNumber}
            btnUrl={''}
          />
          {faqData.details.length > accordionVisibleContentNumber && (
            <ShowMoreBtn
              showMoreText="Daha fazla gör"
              showLessText="Daha az gör"
              onShowState={onShowStateHandler}
              data-testid="showMoreButton"
            />
          )}
        </InnerContainer>
      </>
    );
  } else {
    return null;
  }
};
