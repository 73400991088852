'use client';
import { CampaignContainer } from '@/src/features/ui/campaign-container/campaign-container';
import ImageWithFallback from '@/src/features/ui/image/image-with-fallback';
import RoundedContainer from '@/src/features/ui/rounded-container';
import { CurrencyTypeMissing } from '@/src/interfaces/missing-types';
import { Pages } from '@/src/interfaces/pages-route';
import { DeviceContext } from '@/src/store/contexts/device-context';
import fmt from '@/src/utils/helpers/number-format';
import { isNullOrWhitespace } from '@/src/utils/helpers/validation';
import { useContext, useState } from 'react';
import LinkLikeButton from '../../shared/link-like-button';
import Heading from '../../ui/heading';
import { ApplyWithCustomerStatusPopup } from '../product-list/item/apply-with-customer-status-popup';
import { Button } from '../../ui/button/button';
import React from 'react';
import classNames from 'classnames';
import { CloseIcon } from '../../ui/icons/icons';
import { getCdnPath } from '@/src/utils/get-cdn-path';
import { ResponseModuleStoreFront } from '@/src/interfaces/data-contracts';

type Props = {
  creditCardDetail: Pages.CreditcardDetailList.ResponseBody;
  forwardBank: ResponseModuleStoreFront;
};

export const CardProduct: React.FC<Props> = ({ creditCardDetail, forwardBank }) => {
  const { isMobile } = useContext(DeviceContext);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const defaultModalContent = (
    <section className="flex flex-col gap-8">
      <section className="flex flex-col items-center gap-5">
        {forwardBank && forwardBank.creditCards && forwardBank.creditCards.length > 0 && (
          <figure className="relative h-[68px] w-[146px] rounded-lg border border-background200">
            <ImageWithFallback
              src={
                getCdnPath(forwardBank?.creditCards?.at(0)?.bank?.logoPath) ??
                'https://test-hkcdn.mncdn.com/images/bank/no-bank.svg'
              }
              alt={forwardBank?.creditCards?.at(0)?.bank?.name}
              title={forwardBank?.creditCards?.at(0)?.bank?.name}
              fill
            />
          </figure>
        )}
        {forwardBank &&
          forwardBank.creditCards &&
          forwardBank.creditCards.length > 0 &&
          forwardBank.creditCards.at(0)?.name && (
            <div className="flex flex-col gap-2">
              <Heading tagType={'h3'} cls="text-lg text-neutral900 text-center leading-[28px]" bolded>
                Bankaya yönlendiriliyorsunuz
              </Heading>
              <article
                className="text-center text-sm leading-[28px] text-neutral500"
                dangerouslySetInnerHTML={{
                  __html: forwardBank.creditCards.at(0)?.redirectText ?? '',
                }}
              />
            </div>
          )}
      </section>
      <section>
        {forwardBank &&
          forwardBank.creditCards &&
          forwardBank.creditCards.length > 0 &&
          forwardBank.creditCards.at(0)?.recourseUrl &&
          forwardBank.creditCards.at(0)?.recourseUrl?.trim() !== '' && (
            <LinkLikeButton
              href={forwardBank.creditCards.at(0)?.recourseUrl ?? ''}
              text="Hemen Başvur"
              className="h-[48px] w-full rounded-lg text-sm after:ml-2 after:text-xl after:content-['\2197'] md:text-base"
            />
          )}
      </section>
    </section>
  );

  return (
    <>
      <RoundedContainer
        noShadow={isMobile}
        className="flex flex-col justify-between px-4 py-5 md:flex-row md:px-12 md:py-10"
      >
        <div className="flex w-full flex-col items-center justify-center gap-3 md:gap-5">
          <div className="relative h-[118px] w-[186px] md:h-[180px] md:w-[284px]">
            <ImageWithFallback
              src={creditCardDetail.productInfo?.imageForDetail}
              alt={creditCardDetail.seoInfo?.title}
              fill
              sizes="(max-width: 1120px) 50vw,
              33vw"
            />
          </div>
          <div>
            <Heading tagType="h1" cls="font-bold md:text-base text-sm text-black800">
              {creditCardDetail.productInfo?.bank?.name}
              <span className="ml-1 font-semibold md:text-base">{creditCardDetail.productInfo?.name}</span>
            </Heading>
            {/*todo bottom black color is not correct*/}
          </div>
          {isNullOrWhitespace(creditCardDetail.productInfo?.advantages) ? (
            ''
          ) : (
            <div
              className="table-list-formatting table-border"
              dangerouslySetInnerHTML={{
                __html: creditCardDetail.productInfo?.advantages ?? '',
              }}
            ></div>
          )}
        </div>
        <div className="mt-[34px] flex w-full flex-col justify-between gap-8 md:mt-0">
          <div>
            <div className="flex flex-row justify-between border-b border-t border-black200 py-3 text-black600 md:border-t-0">
              <div className="text-sm font-semibold text-black600">
                {/* {creditCardDetail.productInfo?.annualFeeText} */}
                Yıllık Ücret
              </div>
              <div className="text-base font-bold text-black1000">
                {isNullOrWhitespace(creditCardDetail.productInfo?.annualFeeText) ? (
                  'Ücretsiz'
                ) : (
                  // <div
                  //   dangerouslySetInnerHTML={{
                  //     __html: creditCardDetail.productInfo?.annualFeeAsString ?? '',
                  //   }}
                  // />
                  <div>{fmt(creditCardDetail.productInfo?.annualFee, CurrencyTypeMissing.TRY)}</div>
                )}
              </div>
            </div>
            <div className="flex flex-row justify-between border-b border-black200 py-3">
              <div className="text-sm font-semibold text-black600">Kazanç Türü</div>
              <div className="text-base font-bold text-black1000">{creditCardDetail.productInfo?.gainType}</div>
            </div>
            <div className="flex flex-row justify-between border-b border-black200 py-3">
              <div className="text-sm font-semibold text-black600">Alışveriş Faizi</div>
              <div className="text-base font-bold text-black1000">
                {creditCardDetail.productInfo?.shoppingInterestAsString}
              </div>
            </div>
          </div>
          <div>
            {creditCardDetail.productInfo?.heroCampaigns && creditCardDetail.productInfo.heroCampaigns.length > 0 && (
              <CampaignContainer heroCampaigns={creditCardDetail.productInfo?.heroCampaigns} />
            )}
          </div>
          {creditCardDetail.productInfo?.isPopUpActive ? (
            creditCardDetail.productInfo?.isContract ? (
              <ApplyWithCustomerStatusPopup item={creditCardDetail.productInfo} />
            ) : (
              <Button
                text={creditCardDetail.productInfo?.buttonText ?? 'Hemen Başvur'}
                className="h-[48px] w-full rounded-lg text-sm md:text-base"
                onClick={openPopup}
              />
            )
          ) : (
            creditCardDetail.productInfo?.isContract &&
            creditCardDetail.productInfo?.recourseLink && (
              <LinkLikeButton
                href={creditCardDetail.productInfo.recourseLink}
                text={creditCardDetail?.productInfo?.buttonText ?? 'Hemen Başvur'}
                className="h-[48px] w-full rounded-lg text-sm md:text-base"
              />
            )
          )}
        </div>
      </RoundedContainer>
      {isPopupOpen && (
        <section className="modal-body flex justify-center">
          <section className="fixed left-0 top-0 z-50 flex h-full w-full flex-col bg-black900/[0.59] md:gap-8">
            <section
              className={classNames(
                'absolute left-1/2 top-1/2 w-11/12 -translate-x-1/2 -translate-y-1/2 overflow-auto p-6 px-4',
                'rounded-xl bg-white',
                'md:h-auto md:w-[600px] md:min-w-[600px] md:p-6'
              )}
            >
              <section className={classNames('flex flex-row-reverse')}>
                <figure onClick={closePopup} className="inline-block cursor-pointer">
                  <CloseIcon width={24} height={24} />
                </figure>
              </section>
              {defaultModalContent}
            </section>
          </section>
        </section>
      )}
    </>
  );
};
