'use client';

import { DataLayerService, OriginalLocationEvent, PageDetailsEvent } from '@/lib/data-layer/data-layer';
import { DeviceContext } from '@/src/store/contexts/device-context';
import { OriginalLocationContext } from '@/src/store/contexts/original-location-context';
import React, { memo, useContext, useEffect } from 'react';

export type DatalayerPageEventProps = {
  pageData: any;
  clientData?: any;
};

const dataLayer = new DataLayerService();
export const ComponentPageEvent = memo(function Component({ argms, type }: any) {
  const { isBot } = useContext(DeviceContext);
  const { originalUrl } = useContext(OriginalLocationContext);

  useEffect(() => {
    // if (argms?.page && argms?.otherData) {
    dataLayer.sendEvent(new OriginalLocationEvent(originalUrl ?? window.location.href.toString()));
    // }
  }, [argms]);

  const dispenseMemoizedPage = React.useCallback(
    ({ pageData, clientData }: DatalayerPageEventProps): void => {
      const dataLayer = new DataLayerService();
      // dataLayer.sendEvent(new OriginalLocationEvent(window.location.origin + pageData.virtualPageUrl));
      dataLayer.sendEvent(new PageDetailsEvent({ pageData, clientData, type }));
    },
    [type]
  );

  useEffect(() => {
    if (/* argms?.page && argms?.otherData && */ type === 'page') {
      let preparePageData = {
        ...argms.page,
        ...argms.otherData,
      };
      let clientData = {
        HK_botStatus: isBot,
      };
      dispenseMemoizedPage({
        pageData: preparePageData,
        clientData,
      });
    }
  }, [argms, type, isBot, dispenseMemoizedPage]);

  return null;
});

// export default React.memo(ComponentPageEvent, [areEqual(prevProps, nextProps)]);
