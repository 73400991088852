'use client';
import { ResponseModuleProductLoan } from '@/src/interfaces/data-contracts';
import { CurrencyTypeMissing } from '@/src/interfaces/missing-types';
import fmt from '@/src/utils/helpers/number-format';
import { formatInterestRate } from '@/src/utils/helpers/replace-dot-with-comma';
import React, { useState } from 'react';
import LinkLikeButton from '../../shared/link-like-button';
import ImageWithFallback from '../../ui/image/image-with-fallback';
import { RelLink } from '../../ui/rel-link';
import { Container } from './container';
import Link from 'next/link';
import { Button } from '../../ui/button/button';
import QRComponent from '../../sme/qr-component';

type Props = {
  item: ResponseModuleProductLoan;
  isMobile: boolean;
};

export const Item: React.FC<Props> = ({ item, isMobile }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <Container
      item={item}
      className="smeCard flex flex-col md:flex-row md:items-center md:justify-between"
      data-testid="productCard"
    >
      <div
        className="md:after:border-r-black-200 flex flex-1 items-center md:after:inline-block md:after:h-10  md:after:border-r md:after:pl-8"
        data-testid="BestKobiCreditsForCashNeeds1"
      >
        <div className="sme-img mr-12 flex h-[66px] w-[122px] shrink-0 items-center justify-center rounded-xl border border-black300">
          {item.calculate?.bankListUrl ? (
            <Link
              href={item.calculate?.bankListUrl}
              className="flex h-full w-full items-center justify-center"
              data-testid="bankLink"
            >
              <div className="relative h-5 w-[80px]">
                <ImageWithFallback
                  src={item.bank?.logoPath}
                  alt={
                    item.calculate?.productName
                      ? item.calculate.productName + ' Kobi Kredisi'
                      : 'En Uygun KOBİ Kredileri'
                  }
                  title={item.calculate?.productName}
                  fill
                  data-testid="bankImage"
                />
              </div>
            </Link>
          ) : (
            <div className="relative h-5 w-[80px]">
              <ImageWithFallback
                src={item.bank?.logoPath}
                alt={
                  item.calculate?.productName ? item.calculate.productName + ' Kobi Kredisi' : 'En Uygun KOBİ Kredileri'
                }
                title={item.calculate?.productName}
                fill
                data-testid="bankImage"
              />
            </div>
          )}
        </div>
        <div className="sme-name flex text-xs font-semibold text-black800 md:max-w-[200px] md:text-sm">
          <RelLink href={`${item.calculate?.detailUrl}`} data-testid="productLink">
            {item.calculate?.productName}
          </RelLink>
        </div>
      </div>
      <div className="mt-4 flex flex-row  justify-between md:mt-0 md:gap-5">
        <div className="sme-rate flex flex-col md:min-w-[75px] md:text-right">
          <span className="text-xs text-black700">{item.bank?.financeType === 2 ? 'Kâr Payı' : 'Faiz Oranı'}</span>
          <span className="text-base font-semibold text-black800" data-testid="rate">
            {/* %{item.calculate?.interestRate} */}%{formatInterestRate(item?.calculate?.interestRate)}
          </span>
        </div>
        <div className="sme-inst flex flex-col text-right md:min-w-[124px]" data-testid="productInfo">
          <span className="text-xs text-black700">Aylık Taksit</span>
          <span className="text-base font-semibold text-black800" data-testid="monthlyInstallment">
            {fmt(item.calculate?.monthlyInstallment, item.calculate?.currencyType as unknown as CurrencyTypeMissing)}
          </span>
        </div>
        <div className="sme-total flex flex-col text-right md:min-w-[124px]">
          <span className="text-xs text-black700">Toplam Ödeme</span>
          <span className="text-base font-semibold text-black800" data-testid="totalAmount">
            {fmt(item.calculate?.totalAmount, item.calculate?.currencyType as unknown as CurrencyTypeMissing.TRY)}
          </span>
        </div>
      </div>
      <div className="sme-buttons mt-6 flex flex-row justify-between gap-2 md:ml-9 md:mt-0" data-testid="productCTA">
        {item.calculate?.detailUrl && (
          <LinkLikeButton
            href={item.calculate?.detailUrl}
            text={'İncele'}
            className={'rounde d-lg h-[48px] w-[106px] md:w-[120px]'}
            external
            data-testid="detailLink"
          />
        )}
        {item.calculate?.applyUrl && (!item.isQrPopUpActive || isMobile) && (
          <LinkLikeButton
            href={item.calculate?.applyUrl}
            text={'Hemen Başvur'}
            className="h-[48px] w-[194px] rounded-lg md:w-[182px]"
            data-testid="applyLink"
          />
        )}
        {!isMobile && item.isQrPopUpActive && (
          <>
            <Button
              className="h-[48px] w-[194px] rounded-lg text-sm font-bold md:w-[182px] md:text-base"
              text="Hemen Başvur"
              onClick={openPopup}
              data-testid="applyButton"
            />
            <QRComponent
              closePopup={closePopup}
              isPopupOpen={isPopupOpen}
              product={{ ...item, name: item.calculate?.productName }}
            />
          </>
        )}
      </div>
    </Container>
  );
};
