import { ResponseModuleProductLoan } from '@/src/interfaces/data-contracts';
import { isNullOrWhitespace } from '@/src/utils/helpers/validation';
import classNames from 'classnames';
import React from 'react';
import { StarIcon } from '../../ui/icons/icons';

type Props = {
  children: React.ReactNode;
  className?: string;
  item?: ResponseModuleProductLoan;
};

export const Container: React.FC<Props> = ({ children, className, item, ...rest }) => {
  return (
    <div
      className={classNames(
        'relative rounded-xl bg-white px-4 py-5 shadow-campCard md:px-5 md:py-10',
        className,
        // 'order-' + [item?.displayOrder],
        [!isNullOrWhitespace(item?.badge) ? 'gradientYellowBorderBg pt-[42px]' : 'pt-5']
      )}
      {...rest}
    >
      {item?.badge && (
        <div
          className="absolute left-px top-px flex h-[32px] items-center gap-2 rounded-br-xl rounded-tl-xl bg-warningLight px-4 text-xs font-bold text-warning"
          data-testid="badge"
        >
          <StarIcon width={16} height={16} data-testid="badgeIcon" />
          <span data-testid="badgeText">{item.badge}</span>
        </div>
      )}
      {children}
    </div>
  );
};
