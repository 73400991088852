'use client';
import { CustomerStatusModalContentCreditCard } from '@/src/features/shared/modal-content/customer-status-modal-content-creditcard';
import { Button } from '@/src/features/ui/button/button';
import Modal from '@/src/features/ui/modal/modal';
import { ResponseProductSearch } from '@/src/interfaces/data-contracts';
import { useState } from 'react';

type Props = {
  item: ResponseProductSearch;
};

export const ApplyWithCustomerStatusPopup: React.FC<Props> = ({ item }) => {
  const [showModal, setShowModal] = useState(false);
  const handleApplyClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <Modal
        show={showModal}
        onClose={handleCloseModal}
        customSize
        className="w-[calc(100%-32px)] rounded-xl px-4 py-5 md:w-[600px] md:px-10 md:py-10"
      >
        <CustomerStatusModalContentCreditCard item={item} />
      </Modal>
      {
        <Button
          onClick={handleApplyClick}
          text="Hemen Başvur"
          className="h-[48px] w-full rounded-lg text-sm md:text-base"
          data-testid="customerStatusApplyLink"
        />
      }
    </>
  );
};
