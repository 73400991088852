import { DeviceContext } from '@/src/store/contexts/device-context';
import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/tr';
import Image from 'next/image';
import React, { forwardRef, LegacyRef, Ref, useContext, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import Datetime from 'react-datetime';
import { Controller, useFormContext } from 'react-hook-form';
import Tooltip, { TTooltip } from '../../tooltip/tooltip';
import { EyeOffIcon, EyeOnIcon, PasswordValidateCheck } from '../../icons/icons';

interface InputFieldProps {
  label: string;
  placeholder?: string;
  name: string;
  type?: string;
  append?: React.ReactNode;
  maxLength?: number;
  required?: boolean;
  children?: React.ReactNode;
  showNoneOption?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onPriceChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  preventEInField?: boolean;
  defaultValue?: string | number;
  overrideInputClassName?: string;
  overrideClassName?: string;
  parseSelectAsString?: boolean;
  selectElementNoneOptionColor?: string;
  validationFunc?: (currentDate: moment.Moment) => boolean;
  hasTooltip?: boolean;
  tooltipType?: string;
  tooltipText?: string;
  tooltipPosition?: string;
  tooltipImgSource?: string;
  tooltipIcon?: React.ReactNode;
  tooltipClassname?: string;
  hasMaxLength?: boolean;
  maxLen?: number;
  noPunc?: boolean;
  noPuncWithComma?: boolean;
  checkNo?: boolean;
  digitOnly?: boolean;
  decimalOnly?: boolean;
  preventZeroFirstChar?: boolean;
  isInterestRate?: boolean;
  isInterestRatePunc?: boolean;
  isPassword?: boolean;
  isPasswordCheck?: boolean;
  hasMinLength?: boolean;
  minLen?: number;
  noDigit?: boolean;
  preventLettersAllowNumbers?: boolean;
  icon?: { containerClassName?: string; className?: string; size?: number };
  overrideLabelClassName?: string;
  overrideErrorClassName?: string;
  overrideInputErrorClassName?: string;
  customErrorMessage?: string;
  maxValue?: number;
  tooltipRef?: Ref<TTooltip>;
  radioButtonOptions?: {
    value: string;
    label: string;
  }[];
  errorIcon?: React.ReactNode;
}

const formatInterestRate = (value: string) => {
  const formattedValue = value.replace(/\D+/g, '');

  if (!formattedValue) {
    return formattedValue;
  }
  if (formattedValue) {
    if (formattedValue.toString().length == 1) {
      return `${formattedValue[0]}`;
    }
    if (formattedValue.toString().length == 2) {
      return `${formattedValue[0]},${formattedValue[1]}`;
    }
    if (formattedValue.toString().length == 3) {
      return `${formattedValue[0]},${formattedValue[1]}${formattedValue[2]}`;
    }
  }
};

const InputField: React.FC<InputFieldProps> = forwardRef(
  (
    {
      label,
      name,
      placeholder,
      type = 'text',
      append,
      maxLength,
      required = false,
      showNoneOption = '',
      children,
      onChange,
      onPriceChange,
      preventEInField = false,
      defaultValue,
      overrideInputClassName = '',
      overrideClassName = '',
      parseSelectAsString = false,
      selectElementNoneOptionColor = '',
      validationFunc,
      hasTooltip = false,
      tooltipType,
      tooltipText,
      tooltipPosition,
      tooltipImgSource,
      tooltipIcon,
      tooltipClassname,
      hasMaxLength = false,
      noPunc = false,
      maxLen,
      noPuncWithComma = false,
      checkNo = false,
      digitOnly = false,
      decimalOnly = false,
      preventZeroFirstChar = false,
      isInterestRate = false,
      isInterestRatePunc = false,
      isPasswordCheck = false,
      isPassword = false,
      hasMinLength = false,
      minLen,
      noDigit = false,
      preventLettersAllowNumbers = false,
      icon,
      overrideLabelClassName,
      overrideErrorClassName,
      overrideInputErrorClassName,
      customErrorMessage,
      maxValue,
      tooltipRef,
      radioButtonOptions = [],
      errorIcon,
      ...rest
    },
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const { isMobile } = useContext(DeviceContext);
    const [passwordFieldType, setPasswordFieldType] = useState('password');

    const [isEightDigitsChecked, setIsEightDigitsChecked] = useState(false);
    const [isCapitalCharChecked, setIsCapitalCharChecked] = useState(false);
    const [isNumericCharChecked, setIsNumericCharChecked] = useState(false);

    const checkPasswordRegexes = (event: any, regexState: string) => {
      let inputElement = event.target as HTMLInputElement;
      let value = inputElement.value;
      const leastEightCharRegex = /.{8,}/;
      const numCharRegex = /(?=.*\d)/;
      const capitalCharRegex = /(?=.*[A-Z])/;

      switch (regexState) {
        case 'checkEightDigits': {
          const isValidChanged = leastEightCharRegex.test(value) !== isEightDigitsChecked;
          isValidChanged && setIsEightDigitsChecked((prev) => !prev);
          value.length === 0 && setIsEightDigitsChecked(false);
          return;
        }
        case 'capitalCharRegex': {
          const isValidChanged = capitalCharRegex.test(value) !== isCapitalCharChecked;
          isValidChanged && setIsCapitalCharChecked((prev) => !prev);
          value.length === 0 && setIsCapitalCharChecked(false);
          return;
        }
        case 'numCharRegex': {
          const isValidChanged = numCharRegex.test(value) !== isNumericCharChecked;
          isValidChanged && setIsNumericCharChecked((prev) => !prev);
          value.length === 0 && setIsNumericCharChecked(false);
          return;
        }
      }
    };

    let pValue = '';
    const addLeadingZeros = (event: any) => {
      if (checkNo) {
        const input = event.target;
        const digit = input.value;

        pValue += digit;
        pValue = pValue.slice(-7);

        const paddedValue = pValue.padStart(7, '0');
        input.value = paddedValue;
      }
    };

    const noPuncCond = (event: any) =>
      (noPunc &&
        (event.key === ',' ||
          event.key === '?' ||
          event.key === ';' ||
          event.key === '!' ||
          event.key === ':' ||
          event.key === "'" ||
          event.key === '(' ||
          event.key === ')' ||
          event.key === '[' ||
          event.key === ']' ||
          event.key === '{' ||
          event.key === '}' ||
          event.key === '"' ||
          event.key === '/' ||
          event.key === '@' ||
          event.key === '-' ||
          event.key === '*' ||
          event.key === '_' ||
          event.key === '=' ||
          event.key === '^' ||
          event.key === '%' ||
          event.key === '&' ||
          event.key === '+')) ||
      (noPuncWithComma && event.key === '.');

    const noDigitPrevent = (event: any) =>
      noDigit &&
      (event.key === '1' ||
        event.key === '2' ||
        event.key === '3' ||
        event.key === '4' ||
        event.key === '5' ||
        event.key === '6' ||
        event.key === '7' ||
        event.key === '8' ||
        event.key === '9' ||
        event.key === '0');
    const {
      register,
      formState: { errors },
      control,
      setValue,
      watch,
      trigger,
    } = useFormContext();
    const formValue = watch(name);
    const [inputValue, setInputValue] = useState<string | number>('');
    const [touched, setTouched] = useState(false);
    const error = errors[name];

    useEffect(() => {
      setInputValue(formValue || '');
      trigger(name);
    }, [formValue]);

    useEffect(() => {
      setInputValue(defaultValue || '');
      trigger(name);
    }, [defaultValue]);

    useEffect(() => {
      const subscription = watch(() => {
        setTouched(false);
      });
      return () => subscription.unsubscribe();
    }, [watch]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      let value = event.target.value;

      if (isInterestRate) {
        value = formatInterestRate(value) ?? '';
        event.target.value = value;
        setInputValue(value);
      } else {
        setInputValue(value);
      }

      setValue(name, value);
      setTouched(true);

      if (onChange) {
        handleInput(event);
        onChange(event);
      }
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      if (preventZeroFirstChar && event.target.value.startsWith('0')) {
        event.target.value = event.target.value.substring(1);
      }

      if (onChange) {
        onChange(event);
      }
    };

    const renderInputElement = () => {
      const commonClassNames =
        'h-[52px] md:h-[56px] p-4 md:px-5 md:py-4 bg-white border border-solid rounded-lg w-full';
      const commonTextClassNames = 'text-black800 autofill:text-black800 text-sm font-semibold md:text-base';
      const commonSelectClassNames = 'text-sm font-semibold md:text-base';
      const inputClassName = classNames('border-black300', {
        ['border-black500']: !error && inputValue,
      });
      const overriddenClassName =
        overrideInputClassName !== ''
          ? classNames(overrideInputClassName, { [overrideInputErrorClassName ?? '']: error && touched })
          : classNames(
              commonClassNames,
              { [commonTextClassNames]: type !== 'select' },
              { [commonSelectClassNames]: type === 'select' },
              inputClassName
            );

      const commonProps = register(name, {
        required,
        valueAsNumber: type === 'number' || (type === 'select' && !parseSelectAsString),
      });
      switch (type) {
        case 'select':
          return (
            <select
              data-testid={name}
              {...rest}
              {...commonProps}
              value={inputValue}
              ref={ref as LegacyRef<HTMLSelectElement>}
              onChange={(e) => {
                commonProps.onChange(e);
                handleOnChange(e);
              }}
              className={classNames(overriddenClassName, {
                [selectElementNoneOptionColor]:
                  (selectElementNoneOptionColor !== '' && !inputValue) || inputValue === '-1',
              })}
            >
              {showNoneOption !== '' && <option value="-1">{showNoneOption}</option>}
              {children}
            </select>
          );
        case 'file':
          return (
            <input
              data-testid={name}
              {...rest}
              {...commonProps}
              ref={ref as LegacyRef<HTMLInputElement>}
              onChange={(e) => {
                commonProps.onChange(e);
                handleOnChange(e);
              }}
              type="file"
            />
          );
        case 'textarea':
          return (
            <textarea
              {...rest}
              {...commonProps}
              ref={ref as LegacyRef<HTMLTextAreaElement>}
              data-testid={name}
              onChange={(e) => {
                commonProps.onChange(e);
                handleOnChange(e);
              }}
              placeholder={placeholder}
              className={overriddenClassName}
            />
          );
        case 'datetime':
          return (
            <Controller
              {...rest}
              name={name}
              control={control}
              rules={{ required }}
              defaultValue={defaultValue || ''}
              render={({ field }) => (
                <Datetime
                  data-testid={name}
                  isValidDate={validationFunc}
                  locale={'tr'}
                  {...field}
                  value={field.value ? moment(field.value, 'DD/MM/YYYY').toDate() : (null as any)}
                  onChange={(value) => {
                    const stringValue = typeof value === 'string' ? value : moment(value).format('DD/MM/YYYY');
                    setTouched(true);
                    field.onChange(stringValue);
                  }}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  inputProps={{
                    readOnly: true,
                    placeholder,
                    className: classNames(overriddenClassName),
                    ref: ref as LegacyRef<HTMLInputElement>,
                  }}
                />
              )}
            />
          );
        case 'price':
          return (
            <div className="relative">
              <Controller
                name={name}
                control={control}
                rules={{ required }}
                render={({ field }) => (
                  <CurrencyInput
                    data-testid={name}
                    {...field}
                    min={0}
                    maxLength={maxLength}
                    className={overriddenClassName}
                    onKeyPress={(event) => {
                      const inputElement = event.target as HTMLInputElement;

                      if (noPuncCond(event)) {
                        event.preventDefault();
                      }

                      if (hasMaxLength && inputElement.value.length >= (maxLen ? maxLen : 0)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      onPriceChange?.(e);
                    }}
                    decimalSeparator=","
                    placeholder={placeholder}
                    groupSeparator="."
                    allowNegativeValue={false}
                    onValueChange={(value) => {
                      setTouched(true);
                      // onChange?.(value);
                      field.onChange(
                        maxValue && typeof value === 'string' && parseInt(value) > maxValue
                          ? maxValue.toString()
                          : value
                      );
                    }}
                  />
                )}
              />
              <span className="absolute right-2 top-1/2 -translate-y-1/2">{append}</span>
            </div>
          );
        case 'passwordWithEyeIcon':
          return (
            <div className="group relative inline-block">
              <input
                data-testid={name}
                {...rest}
                {...commonProps}
                ref={ref as LegacyRef<HTMLInputElement>}
                value={inputValue}
                onChange={(e) => {
                  commonProps.onChange(e);
                  handleOnChange(e);
                  checkPasswordRegexes(e, 'checkEightDigits');
                  checkPasswordRegexes(e, 'capitalCharRegex');
                  checkPasswordRegexes(e, 'numCharRegex');
                }}
                // prevent e and E for number inputs
                onKeyUp={(event) => {
                  let inputElement = event.target as HTMLInputElement;
                  let value = inputElement.value;

                  if (hasMaxLength && value.length >= (maxLen ? maxLen : 0)) {
                    inputElement.value = value.slice(0, maxLen);
                    event.preventDefault();
                  }
                }}
                type={passwordFieldType}
                placeholder={placeholder}
                className={overriddenClassName}
              />
              <span
                className={
                  icon?.containerClassName
                    ? icon?.containerClassName
                    : classNames('absolute right-2 top-1/2 -translate-y-1/2', { 'top-[30px]': !isPasswordCheck })
                }
              >
                {passwordFieldType === 'password' ? (
                  //todo: update icon with eye-on when design team adds it
                  <EyeOffIcon
                    className={`${icon?.className || 'cursor-pointer stroke-[#888888]'}`}
                    width={icon?.size || 20}
                    height={icon?.size || 20}
                    onClick={() => setPasswordFieldType('text')}
                  />
                ) : (
                  <EyeOnIcon
                    className={`${icon?.className || 'cursor-pointer stroke-[#888888]'}`}
                    width={icon?.size || 20}
                    height={icon?.size || 20}
                    onClick={() => setPasswordFieldType('password')}
                  />
                )}
              </span>

              {customErrorMessage && (
                <div className="mt-[6px] flex gap-[7.5px] md:gap-4">
                  <div className="flex gap-1 whitespace-nowrap">
                    <span className="text-xs leading-[18px] text-error">{customErrorMessage}</span>
                  </div>
                </div>
              )}

              {!isPasswordCheck && (
                <div className="mt-[6px] flex gap-[7.5px] md:gap-4">
                  <div className="flex gap-1 whitespace-nowrap">
                    <PasswordValidateCheck
                      width="16px"
                      height="16px"
                      className={classNames('text-neutral400', 'group-focus-within:text-purple404', {})}
                      style={{ color: isEightDigitsChecked ? '#16A34A' : '' }}
                    />
                    <p
                      className={classNames('text-xs text-neutral400', 'group-focus-within:text-purple404')}
                      style={{ color: isEightDigitsChecked ? '#16A34A' : '' }}
                    >
                      En az 8 karakter
                    </p>
                  </div>
                  <div className="flex gap-1  whitespace-nowrap">
                    <PasswordValidateCheck
                      width="16px"
                      height="16px"
                      className={classNames('text-neutral400', 'group-focus-within:text-purple404')}
                      style={{ color: isCapitalCharChecked ? '#16A34A' : '' }}
                    />
                    <p
                      className={classNames('text-xs text-neutral400', 'group-focus-within:text-purple404')}
                      style={{ color: isCapitalCharChecked ? '#16A34A' : '' }}
                    >
                      En az 1 büyük harf
                    </p>
                  </div>
                  <div className="flex gap-1  whitespace-nowrap">
                    <PasswordValidateCheck
                      width="16px"
                      height="16px"
                      className={classNames('text-neutral400', 'group-focus-within:text-purple404')}
                      style={{ color: isNumericCharChecked ? '#16A34A' : '' }}
                    />
                    <p
                      className={classNames('text-xs text-neutral400', 'group-focus-within:text-purple404')}
                      style={{ color: isNumericCharChecked ? '#16A34A' : '' }}
                    >
                      En az 1 rakam
                    </p>
                  </div>
                </div>
              )}
            </div>
          );
        case 'passwordWithEyeIcon':
          return (
            <div className="group relative inline-block">
              <input
                data-testid={name}
                {...rest}
                {...commonProps}
                ref={ref as LegacyRef<HTMLInputElement>}
                onChange={(e) => {
                  commonProps.onChange(e);
                  handleOnChange(e);
                  checkPasswordRegexes(e, 'checkEightDigits');
                  checkPasswordRegexes(e, 'capitalCharRegex');
                  checkPasswordRegexes(e, 'numCharRegex');
                }}
                // prevent e and E for number inputs
                onKeyUp={(event) => {
                  let inputElement = event.target as HTMLInputElement;
                  let value = inputElement.value;

                  if (hasMaxLength && value.length >= (maxLen ? maxLen : 0)) {
                    inputElement.value = value.slice(0, maxLen);
                    event.preventDefault();
                  }
                }}
                type={passwordFieldType}
                placeholder={placeholder}
                className={overriddenClassName}
              />
              <span
                className={
                  icon?.containerClassName
                    ? icon?.containerClassName
                    : classNames('absolute right-2 top-1/2 -translate-y-1/2', { 'top-[30px]': !isPasswordCheck })
                }
              >
                {passwordFieldType === 'password' ? (
                  //todo: update icon with eye-on when design team adds it
                  <EyeOffIcon
                    className={`${icon?.className || 'cursor-pointer stroke-[#888888]'}`}
                    width={icon?.size || 20}
                    height={icon?.size || 20}
                    onClick={() => setPasswordFieldType('text')}
                  />
                ) : (
                  <EyeOnIcon
                    className={`${icon?.className || 'cursor-pointer stroke-[#888888]'}`}
                    width={icon?.size || 20}
                    height={icon?.size || 20}
                    onClick={() => setPasswordFieldType('password')}
                  />
                )}
              </span>

              {customErrorMessage && (
                <div className="mt-[6px] flex gap-[7.5px] md:gap-4">
                  <div className="flex gap-1 whitespace-nowrap">
                    <span className="text-xs leading-[18px] text-error">{customErrorMessage}</span>
                  </div>
                </div>
              )}

              {!isPasswordCheck && (
                <div className="mt-[6px] flex gap-[7.5px] md:gap-4">
                  <div className="flex gap-1 whitespace-nowrap">
                    <PasswordValidateCheck
                      width="16px"
                      height="16px"
                      className={classNames('text-neutral400', 'group-focus-within:text-purple404')}
                      style={{ color: isEightDigitsChecked ? '#16A34A' : '' }}
                    />
                    <p
                      className={classNames('text-xs text-neutral400', 'group-focus-within:text-purple404')}
                      style={{ color: isEightDigitsChecked ? '#16A34A' : '' }}
                    >
                      En az 8 karakter
                    </p>
                  </div>
                  <div className="flex gap-1  whitespace-nowrap">
                    <PasswordValidateCheck
                      width="16px"
                      height="16px"
                      className={classNames('text-neutral400', 'group-focus-within:text-purple404')}
                      style={{ color: isCapitalCharChecked ? '#16A34A' : '' }}
                    />
                    <p
                      className={classNames('text-xs text-neutral400', 'group-focus-within:text-purple404')}
                      style={{ color: isCapitalCharChecked ? '#16A34A' : '' }}
                    >
                      En az 1 büyük harf
                    </p>
                  </div>
                  <div className="flex gap-1  whitespace-nowrap">
                    <PasswordValidateCheck
                      width="16px"
                      height="16px"
                      className={classNames('text-neutral400', 'group-focus-within:text-purple404')}
                      style={{ color: isNumericCharChecked ? '#16A34A' : '' }}
                    />
                    <p
                      className={classNames('text-xs text-neutral400', 'group-focus-within:text-purple404')}
                      style={{ color: isNumericCharChecked ? '#16A34A' : '' }}
                    >
                      En az 1 rakam
                    </p>
                  </div>
                </div>
              )}
            </div>
          );
        case 'radio':
          return (
            <div className={classNames('flex gap-8')}>
              {radioButtonOptions?.map((option) => (
                <label key={option.value} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    value={option.value}
                    {...register(name, { required })}
                    onChange={(e) => {
                      commonProps.onChange(e);
                      handleOnChange(e);
                      // setOptionValue(option.value)
                    }}
                    className="peer hidden"
                  />
                  <div className="h-4 w-4 rounded-full border-2 border-gray25 peer-checked:border-4 peer-checked:border-purple404 peer-checked:bg-white"></div>
                  <span className="text-sm text-neutral900">{option.label}</span>
                </label>
              ))}
            </div>
          );
        default:
          return (
            <div className="relative inline-block">
              <input
                data-testid={name}
                {...rest}
                {...commonProps}
                ref={ref}
                value={inputValue}
                onChange={(e) => {
                  let inputElement = e.target as HTMLInputElement;
                  let value = inputElement.value;

                  if (decimalOnly && isInterestRatePunc) {
                    value = value.replace(/^%/, '');

                    value = value.replace(/,+/g, '');

                    if (/^\d+$/.test(value)) {
                      if (value.length <= 2) {
                        inputElement.value = `%${value}`;
                      } else if (value.length === 3 && value === '100') {
                        inputElement.value = `%100`;
                      } else if (value.length <= 4) {
                        const integerPart = value.slice(0, value.length - 2);
                        const decimalPart = value.slice(-2);
                        inputElement.value = `%${integerPart},${decimalPart}`;
                      } else {
                        inputElement.value = '%99,99';
                      }
                    } else {
                      inputElement.value = '%';
                    }
                  }

                  commonProps.onChange(e);
                  handleOnChange(e);
                }}
                // prevent e and E for number inputs
                onKeyUp={(event) => {
                  let inputElement = event.target as HTMLInputElement;
                  let value = inputElement.value;
                  if (hasMaxLength && value.length >= (maxLen ? maxLen : 0)) {
                    inputElement.value = value.slice(0, maxLen);
                    event.preventDefault();
                  }
                  if (hasMinLength && value.length <= (minLen ? minLen : 0)) {
                    event.preventDefault();
                  }
                }}
                onKeyDown={(event) => {
                  let inputElement = event.target as HTMLInputElement;
                  let value = inputElement.value;
                  if ((type === 'number' || preventEInField) && (event.key === 'e' || event.key === 'E')) {
                    event.preventDefault();
                  }
                  if (noPuncCond(event)) {
                    event.preventDefault();
                  }
                  if (noDigitPrevent(event)) {
                    event.preventDefault();
                  }
                  if (hasMaxLength && value.length >= (maxLen ? maxLen : 0) && event.code != 'Backspace') {
                    event.preventDefault();
                  }
                  if (digitOnly && event.key.length === 1 && isNaN(Number(event.key)) && event.code != 'Backspace') {
                    event.preventDefault();
                  }
                  if (
                    decimalOnly &&
                    event.key.length === 1 &&
                    isNaN(Number(event.key)) &&
                    event.code != 'Backspace' &&
                    event.key != ','
                  ) {
                    event.preventDefault();
                  }
                  if (
                    preventLettersAllowNumbers &&
                    !/[0-9]/.test(event.key) &&
                    event.key !== 'Backspace' &&
                    event.key !== 'Delete'
                  ) {
                    event.preventDefault();
                  }
                }}
                onInput={
                  checkNo
                    ? (event) => {
                        addLeadingZeros(event);
                      }
                    : () => {}
                }
                type={type}
                placeholder={placeholder}
                className={overriddenClassName}
              />
              <span className={classNames('absolute right-2 top-1/2 -translate-y-1/2', '')}>{append}</span>
            </div>
          );
      }
    };

    return hasTooltip ? (
      <div className={classNames(overrideClassName !== '' ? overrideClassName : 'flex flex-1 flex-col')}>
        <div className="mb-2 flex items-center gap-1">
          <label
            className={classNames(overrideLabelClassName ?? 'text-xs font-semibold md:text-sm')}
            data-testid={name + 'Label'}
          >
            {label}
          </label>
          <Tooltip
            tooltipType={tooltipType}
            tooltipText={tooltipText}
            tooltipIcon={tooltipIcon}
            tooltipClassname={tooltipClassname}
            className="ml-1 text-xs md:text-base"
            ref={tooltipRef}
            // @ts-ignore
            position={tooltipPosition}
            optionalClasses={
              tooltipType == 'info' && tooltipImgSource ? 'md:w-[600px] w-[300px]' : 'md:w-[238px] w-[202px]'
            }
          >
            {tooltipType == 'info' && tooltipImgSource ? (
              // @ts-ignore
              <Image src={tooltipImgSource} alt={label} width={isMobile ? 300 : 600} height={isMobile ? 300 : 600} />
            ) : (
              tooltipText
            )}
          </Tooltip>
          {label !== '' ? (
            // <HelpCircleIcon className="text-black700 w-4 md:w-[18px] h-4 md:h-[18px] cursor-help" />
            // todo turned off for now until new development
            <></>
          ) : (
            <div className="h-[18px] w-[18px] md:h-[21px] md:w-[21px]"></div>
          )}
        </div>
        {renderInputElement()}
        {/*{error && <p className="text-error text-xs md:text-sm">{error.message as React.ReactNode}</p>}*/}

        {error && touched && !isPassword && (
          <p className={overrideErrorClassName ?? 'text-xs text-error md:text-sm'} data-testid={name + 'Error'}>
            {errorIcon} {error.message as React.ReactNode}
          </p>
        )}
      </div>
    ) : (
      <div className={classNames(overrideClassName !== '' ? overrideClassName : 'flex flex-1 flex-col')}>
        <div className="mb-2 flex items-center gap-1">
          <label
            className={classNames(overrideLabelClassName ?? 'text-xs font-semibold md:text-sm')}
            data-testid={name + 'Label'}
          >
            {label}
          </label>
          {label !== '' ? (
            // <HelpCircleIcon className="text-black700 w-4 md:w-[18px] h-4 md:h-[18px] cursor-help" />
            // todo turned off for now until new development
            <></>
          ) : (
            <div className="h-[18px] w-[18px] md:h-[21px] md:w-[21px]"></div>
          )}
        </div>
        {renderInputElement()}
        {/*{error && <p className="text-error text-xs md:text-sm">{error.message as React.ReactNode}</p>}*/}
        {error && touched && !isPassword && (
          <p className={overrideErrorClassName ?? 'text-xs text-error md:text-sm'} data-testid={name + 'Error'}>
            {errorIcon} {error.message as React.ReactNode}
          </p>
        )}
      </div>
    );
  }
);

InputField.displayName = 'InputField';
export default InputField;
