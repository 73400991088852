import { getDeviceType } from '@/src/store/get-device-type';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generateGUID } from '@/lib/generate-guid';

export const chequeService = createApi({
  reducerPath: 'chequeService',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers) => {
      headers.set('device', getDeviceType());
      headers.set('CorrelationId', generateGUID());
      return headers;
    },
  }),
  endpoints: (builder) => ({
    districts: builder.query({
      query: (params) => ({
        url: `/pages/content/${params.cityId}/districts`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyDistrictsQuery } = chequeService;
