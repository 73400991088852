import cx from 'classnames';
import React, { JSXElementConstructor } from 'react';
import { CloseIcon } from '../icons/icons';

type Props = {
  title?: string | null;
  children: React.ReactNode;
  show: boolean;
  className?: string;
  onCloseShow?: boolean | false;
  onClose?: (...args: any) => void;
  customSize?: boolean;
  closeButton?: React.ReactNode;
};

const Modal: React.FC<Props> = ({
  title,
  show,
  children,
  className,
  onCloseShow,
  customSize,
  onClose,
  closeButton,
  ...rest
}) => {
  return (
    <div
      className={cx('fixed inset-0 z-50 bg-black900 bg-opacity-25', {
        hidden: !show,
      })}
      {...rest}
    >
      <div
        className={cx(
          'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white p-8 shadow-modal',
          { 'modal-dialog': !customSize },
          className
        )}
      >
        {!onCloseShow &&
          (closeButton ?? (
            <button
              className="b-none absolute right-4 top-4 bg-transparent"
              type="button"
              onClick={onClose}
              data-testid="closeButton"
            >
              <CloseIcon width="20px" height="20px" data-testid="closeIcon" />
            </button>
          ))}
        {title && (
          <div className="mb-2 text-base font-semibold leading-6 tracking-tighter text-[#090816]" data-testid="title">
            {title}
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export default Modal;
